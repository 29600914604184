(function ($) {
    $.ajaxDialog = function (url, options) {
        $.loading(true);
        var deferred = $.Deferred();

        if ($("#page-modal-container").length == 0) {
            $("<div id=\"page-modal-container\"></div>").appendTo("body");
        }

        $.ajax({
            type: "GET",
            url: url,
            cache: false,
            headers: { 'X-Request-Href': location.href }
        }).done(function(data) {
            $.loading(false);

            //if ($("#page-modal").length > 0)
            //    $("#page-modal").modal("hide");

            // Modal already active
            if ($("#page-modal:visible").length > 0) {
                $('.modal-backdrop').remove();
            }

            $("#page-modal-container").html(data);
            $("#page-modal").modal("show");

            $('#page-modal').initializeContextBindings();

            if (options !== undefined && "callback" in options) {
                options.callback($("#page-modal"));
            }

            var $form = $("#page-modal form");
            if ($form.length === 1) {
                $form.on("submitDone", function(e, formReturnData) {
                    $("#page-modal").modal("hide");
                    deferred.resolve(formReturnData);
                });
            }
        }).fail(function(xhr, textStatus, errorThrown) {
            $.loading(true);
            alert(errorThrown);
        });

        return deferred.promise();
    };

}(jQuery));